<template>
  <div class="successDetail">
    <Head :type="2"></Head>
    <div class="successDetail_con">
      <div class="launch_con">
        <h3 class="h3">{{ successDetail.title }}</h3>
        <div v-html="successDetail.content"></div>
      </div>
    </div>
    <RightSide></RightSide>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";
import RightSide from "@/components/RightSide.vue";
import { getsucDetail } from "@/api/common";

export default {
  name: "SuccessDetail",
  components: {
    Head,
    Footer,
    RightSide,
  },
  data() {
    return {
      id: "",
      successDetail: {},
    };
  },
  methods: {
    getsucDetail() {
      getsucDetail(this.id).then((res) => {
        if (res.data.code == 200) {
          this.successDetail = res.data.data;
        } else {
        }
      });
    },
  },
  mounted() {
    this.id = this.$route.query.index;
    this.getsucDetail();
  },
};
</script>
<style scoped lang="less">
.successDetail {
  .successDetail_con {
    width: 1280px;
    margin: 0 auto;
    box-sizing: border-box;
    margin-bottom: 146px;
  }
  .h3 {
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    line-height: 37px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
  }
}
</style>
